// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-for-funding-js": () => import("./../../../src/pages/apply-for-funding.js" /* webpackChunkName: "component---src-pages-apply-for-funding-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-philosophy-js": () => import("./../../../src/pages/investment-philosophy.js" /* webpackChunkName: "component---src-pages-investment-philosophy-js" */),
  "component---src-pages-our-portfolio-js": () => import("./../../../src/pages/our-portfolio.js" /* webpackChunkName: "component---src-pages-our-portfolio-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

