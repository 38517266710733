/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onClientEntry = () => {
  window.addEventListener("ready", () => {
    // document.body.className = document.body.className.replace(/\bno-js\b/, "")
    console.log("load called");
  })
}
